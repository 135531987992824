
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "confirmdialog.titledefault",
    },
    text: {
      type: String,
      default: "confirmdialog.textdefault",
    },
    multilineText: {
      type: Array,
      // default: function() {
      //   return [""];
      // },
      required: false,
    },
    btnLeftLabel: {
      type: String,
      default: "basic.btn.cancel",
    },
    btnRightLabel: {
      type: String,
      default: "basic.btn.confirm",
    },
  },
  methods: {
    btnClicked(answer: Boolean) {
      this.$emit("btnClicked", answer);
    },
  },
});
